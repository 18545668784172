import { Injectable } from '@angular/core';

import { Router,NavigationExtras, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from '../guards/auth.service';
import { Login, LoginSuccess } from '../pages/login/login.model';
/*Router*/


@Injectable()
export class AuthGuard implements CanActivate {
  userdata: LoginSuccess;
  constructor(private router: Router, private auth: AuthService) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
 

    var user = localStorage.getItem("user")

    if(user.length>0){
      var userdata:LoginSuccess = JSON.parse(user);

          if (((isNaN(userdata.IsCloseItSubScriber) || userdata.IsCloseItSubScriber != 1) && userdata.numberofDays > 7)) {
  
        let navigationExtras: NavigationExtras = {
          state: {
            userData:true,
            subscribe:true,
            ismenu:false
            
           }
        };
        this.router.navigate(["/registration"], navigationExtras);
  
       } else{
  
        return true
  
   
  
       }

    }else{

      
      let navigationExtras: NavigationExtras = {
        state: {
          userData:true,
          subscribe:true,
          ismenu:false
          
         }
      };
        this.router.navigate(["/login"], navigationExtras);

    }
   
   
   
}


}
