import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialComponentsModule } from './material-components/material-components.module';
import { AuthGuard } from './guards/auth.guard';
import { NgxStripeModule } from 'ngx-stripe';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
@NgModule({
    declarations: [AppComponent],
  
    imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, BrowserAnimationsModule, MaterialComponentsModule, NgIdleKeepaliveModule.forRoot(),
        NgxStripeModule.forRoot('pk_live_asRVDIPkKIIGiGBoBdVQ2ASp00cM8sDqV0'),],
  providers: [
    StatusBar,
    AuthGuard,
      SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
