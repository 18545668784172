import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
      path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'login/:id',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
  {
    path: 'menu',
    loadChildren: () => import('./pages/menu/menu.module').then(m => m.MenuPageModule),
    canActivate: [AuthGuard]
     
  },
  {
    path: 'registration',
    loadChildren: () => import('./pages/registration/registration.module').then( m => m.RegistrationPageModule),
    
    },
    {
        path: 'registration/:email',
        loadChildren: () => import('./pages/registration/registration.module').then(m => m.RegistrationPageModule),

    },
    {
        path: 'validateform',
        loadChildren: () => import('./pages/ValidateLogin/validate.module').then(m => m.ValidatePageModule),

    },
    {
        path: 'forgetPassword',
        loadChildren: () => import('./pages/ForgetPassword/password.module').then(m => m.ForgetPasswordPageModule),

    },
 
  {
    path: 'documents',
    loadChildren: () => import('./pages/documents/documents.module').then( m => m.DocumentsPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./dialogs/payment/payment.module').then( m => m.PaymentPageModule)
  },
  // {
  //   path: 'emailit',
  //   loadChildren: () => import('./dialogs/emailit/emailit.module').then( m => m.EmailitPageModule)
  // },
  // {
  //   path: 'askit',
  //   loadChildren: () => import('./dialogs/askit/askit.module').then( m => m.AskitPageModule)
  // }
 
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
